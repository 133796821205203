import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TableModalComponent } from './TableModalComponent';
export const ModalComponent = ({
	typeInvitation,
	openModal,
	setOpenModal,
	sendInvitationsAction,
	sendUnInviteAction,
	collaboratorsList,
	handleModalClose,
}) => {
	const renderTable = () => {
		return (
			<TableModalComponent
				filtersList={collaboratorsList}
				typeInvitation={typeInvitation}
			/>
		);
	};
	const content = () => {
		return (
			<div className='flex flex-col w-full h-full space-y-2'>
				<p>
					{typeInvitation === 'invitation'
						? 'No se pudo enviar la invitación a los siguientes colaboradores: '
						: 'No se pudo anular la invitación a los siguientes colaboradores, debido a que no cuentan con una invitación previa'}
				</p>
				{renderTable()}
			</div>
		);
	};

	return (
		<Dialog
			header={
				typeInvitation === 'invitation'
					? 'Envío masivo de invitaciones'
					: 'Anulación masiva de invitaciones'
			}
			visible={openModal}
			modal
			style={{ width: '60vw', position: 'relative', height: '60vh' }}
			onHide={() => {
				setOpenModal(false);
				handleModalClose();
			}}>
			{content()}
		</Dialog>
	);
};

import { SidebarNames } from '../namesConstants/names';

export const sidebarRoutes = [
	{
		id: 'invitations',
		name: 'invitations',
		url: '/invitations',
		title: SidebarNames.SidebarInvitations,
		tapTitle: SidebarNames.SidebarInvitations,
		icon: 'HiOutlineMail',
		translate: SidebarNames.SidebarInvitations,
		type: 'item',
		show: true,
	},
	{
		id: 'applications',
		url: '/applications',
		name: 'applications',
		title: SidebarNames.SidebarApplications,
		tapTitle: SidebarNames.SidebarApplications,
		icon: 'RiListCheck2',
		translate: SidebarNames.SidebarApplications,
		type: 'item',
		show: true,
	},
	{
		id: 'assist',
		url: '/assist',
		name: 'assist',
		title: SidebarNames.SidebarNameAssist,
		tapTitle: SidebarNames.SidebarNameAssist,
		icon: 'AiOutlineSchedule',
		translate: SidebarNames.SidebarNameAssist,
		type: 'item',
		show: true,
		childrends: [
			{
				id: 'catalogs',
				url: '/catalogs',
				name: 'catalogs',
				title: SidebarNames.SidebarCatalogs,
				tapTitle: SidebarNames.SidebarCatalogs,
				icon: 'SiDatabricks',
				translate: SidebarNames.SidebarCatalogs,
				type: 'item',
				open: false,
			},
			{
				id: 'configuration',
				url: '/configuration',
				name: 'configuration',
				title: SidebarNames.SidebarConfiguration,
				tapTitle: SidebarNames.SidebarConfiguration,
				icon: 'IoSettingsOutline',
				translate: SidebarNames.SidebarConfiguration,
				type: 'item',
			},
			{
				id: 'incidences',
				url: '/incidences',
				name: 'incidences',
				title: SidebarNames.SidebarIncidences,
				tapTitle: SidebarNames.SidebarIncidences,
				icon: 'BsPersonFill',
				translate: SidebarNames.SidebarIncidences,
				type: 'item',
			},
			{
				id: 'operation',
				url: '/operation',
				name: 'operation',
				title: SidebarNames.SidebarOperation,
				tapTitle: SidebarNames.SidebarOperation,
				icon: '',
				translate: SidebarNames.SidebarOperation,
				type: 'item',
			},
			{
				id: 'queries',
				url: '/queries',
				name: 'queries',
				title: SidebarNames.SidebarQueries,
				tapTitle: SidebarNames.SidebarQueries,
				icon: '',
				translate: SidebarNames.SidebarQueries,
				type: 'item',
			},
		],
	},
];

import React, { useState } from 'react';
import { OperationNames } from '../../namesConstants/names';

import { EmployeeManagement } from './EmployeeManagement';
import { Checks } from './Checks/Index';
import { IntegrationComponent } from '../../components/IntegrationComponent';
import { SidebarCatalogsComponent } from '../../components/SidebarCatalogsComponent';
export const Operations = () => {
	const [itemSelected, setItemSelected] = useState(1);
	const [currentTap, setCurrentTap] = useState({ id: 1 });
	const [isOpen, setIsOpen] = useState(true);

	const children = [
		{
			name: OperationNames.ScreenTitle,
			icon: 'AiOutlineSchedule',
			children: [
				{
					id: 1,
					url: '/operation/employeeManagement',
					name: 'employeeManagement',
					title: OperationNames.Collaborators,
					tapTitle: OperationNames.Collaborators,
					icon: 'FaUserCog',
					translate: OperationNames.Collaborators,
					type: 'item',
				},
				// {
				// 	id: 2,
				// 	url: '/operation/checks',
				// 	name: 'schedules',
				// 	title: OperationNames.RegisterCheck,
				// 	tapTitle: OperationNames.RegisterCheck,
				// 	icon: 'AiOutlineSchedule',
				// 	translate: OperationNames.RegisterCheck,
				// 	type: 'item',
				// },
			],
		},
	];
	const renderComponent = (valueSelect) => {
		switch (valueSelect) {
			case 1:
				return <EmployeeManagement />;
			case 2:
				return <Checks />;
		}
	};
	return (
		<IntegrationComponent>
			<div className='flex w-full h-full flex-row'>
				<SidebarCatalogsComponent
					isOpen={isOpen}
					setCurrentTap={setCurrentTap}
					currentTap={currentTap}
					setIsOpen={setIsOpen}
					setItemSelected={setItemSelected}
					Data={children}
				/>
				<div className='flex h-full flex-grow'>
					{renderComponent(itemSelected)}
				</div>
			</div>
		</IntegrationComponent>
	);
};

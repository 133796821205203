import React, { useState, useEffect, useContext } from 'react';
import { Dialog } from 'primereact/dialog';

//components
import { size } from './utilsTable';
import { Toast } from './../../components/toast/index';
import { AlertComponent } from './components/AlertComponent';
import { TableComponent } from './components/TableComponent';
import { HeaderComponent } from './components/HeaderComponent';
import { MainContainer } from '../../components/MainContainer';
import { ModalComponent } from './components/ModalComponent';

//utils and constants
import {
	StatusArrayValuesTable,
	validateStartDateAndEndDate,
	validateStartDate,
	validateEndDate
} from '../../utils/utils';
import {
	InvitationsScreenNames,
	AlertToastInfoNames,
} from '../../namesConstants/names';
import {
	EmployeeStatusEnum,
	tableArrayStateEmployeeOptions,
	tableArrayStateOptions,
} from './const';

//hooks and context
import { StoreContext } from './../../business/Provider';
import useReload from './../../hooks/useReload';
import useInvitations from './../../hooks/invitations/useInvitations';
import { FilterMatchMode } from 'primereact/api';

export const InvitationsScreen = () => {
	const { getInvitationsState, loadingState, companyState } =
		useContext(StoreContext);
	const {
		getInvitations,
		sendInvitation,
		sendUnInvite,
		verifyAvailableLicense,
	} = useInvitations();

	useReload([getInvitations]);

	const [dialogContent, setDialogContent] = useState('');
	const [endDate, setEndDate] = useState(null);
	const [filterOpc] = useState(tableArrayStateOptions);
	const [filterOpcEmployee] = useState(tableArrayStateEmployeeOptions);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [filtersList, setFiltersList] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [individualRow, setIndividualRow] = useState({});
	const [isIndividual, setIsIndividual] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [collaboratorsList, setCollaboratorsList] = useState([]);
	const [selectFilter, setSelectFilter] = useState({ status: 'all' });
	const [selectFilterEmployee, setSelectFilterEmployee] = useState({
		statusEmployee: '0',
	});
	const [showCloseDialog, setShowCloseDialog] = useState(false);
	const [sort, setSort] = useState([{ field: 'code', order: -1 }]);
	const [startDate, setStartDate] = useState(null);
	const [toSend, setToSend] = useState([]);
	const [typeInvitation, setTypeInvitation] = useState('invitation');
	useEffect(() => {
		if (
			getInvitationsState.loading === false &&
			getInvitationsState.invitations !== undefined
		) {
			setFiltersList(getInvitationsState.invitations);
		}
	}, [getInvitationsState.invitations]);

	useEffect(() => {
		let data =
			getInvitationsState?.invitations !== undefined
				? getInvitationsState.invitations
				: [];
		let newItem =
			selectFilter.status !== 'all'
				? data.filter((item) => {
						if (selectFilter.status === 'unavailable') {
							return item.email === null;
						}
						if (selectFilter.status === 0) {
							return item.email !== null && item.invitationStatus === 0;
						} else {
							return item.invitationStatus === selectFilter.status;
						}
				  })
				: data;
		// Aplicar filtro adicional para statusEmployee
		newItem =
			newItem !== undefined
				? newItem.filter((item) => {
						const statusEmployee = parseInt(
							selectFilterEmployee.statusEmployee
						);
						// Validar si statusEmployee es 0 o 1
						if (statusEmployee === 0 || statusEmployee === 1) {
							return item.statusEmployee === statusEmployee;
						}
						// Si statusEmployee no es 0 ni 1, no aplicar filtro y devolver el item, ya que seleccionó todos
						return true;
				  })
				: newItem;
		if (startDate !== null && endDate === null) {
			const ArrayListByStartDate = validateStartDate(startDate, newItem);
			setFiltersList(ArrayListByStartDate);
		} else if (startDate === null && endDate !== null) {
			const ArrayListByEndDate = validateEndDate(endDate, newItem);
			setFiltersList(ArrayListByEndDate);
		} else if (startDate !== null && endDate !== null) {
			const ArrayListByDate = validateStartDateAndEndDate(
				startDate,
				endDate,
				newItem
			);
			setFiltersList(ArrayListByDate);
		} else {
			setFiltersList(newItem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectFilter,
		startDate,
		endDate,
		getInvitationsState,
		selectFilterEmployee,
	]);

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setSelectFilter({
			...selectFilter,
			[name]: value,
		});
	};
	const handleFilterEmployee = (e) => {
		const { name, value } = e.target;
		setSelectFilterEmployee({
			...selectFilterEmployee,
			[name]: value,
		});
	};

	const returnRenderDialog = () => {
		setOpenDialog(true);
		setIsIndividual(false);
		setTypeInvitation('invitation');
	};
	const sendUnInviteFunctionActionMultiple = () => {
		setOpenDialog(true);
		setTypeInvitation('un-invite');
		setIsIndividual(false);
	};
	function getDataExport(listInvitation) {
		let arrayExport = [];
		listInvitation.forEach((invitation) => {
			let filterStatus = StatusArrayValuesTable.filter(
				(item) => item.value === invitation.invitationStatus
			)[0].label;
			let invitationStatus =
				invitation.email === null || invitation.email === ''
					? 'No disponible'
					: filterStatus;
			let data = {
				formattedCode: parseInt(invitation.formattedCode),
				fullName: invitation.fullName,
				email: invitation.email,
				statusEmployee: EmployeeStatusEnum[invitation.statusEmployee],
				invitationSendingDate:
					invitation.invitationSendingDate ?? 'No disponible',
				invitationResponseDate:
					invitation.invitationResponseDate ?? 'No disponible',
				invitationStatus: invitationStatus,
				additionalInfo: invitation.additionalInfo,
			};
			arrayExport.push(data);
		});
		return arrayExport;
	}

	const acceptFunction = () => {
		return toSend.length === 0
			? Toast(
					'warning',
					InvitationsScreenNames.AlertValidationEmptyArrayTitle,
					InvitationsScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: returnRenderDialog();
	};
	const dismissFunction = () => {
		return toSend.length === 0
			? Toast(
					'warning',
					InvitationsScreenNames.AlertValidationEmptyArrayTitle,
					InvitationsScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: sendUnInviteFunctionActionMultiple();
	};

	const clearFilters = () => {
		setSelectFilter({ status: 'all' });
		setSelectFilterEmployee({ statusEmployee: '0' });
		setStartDate(null);
		setEndDate(null);
		setGlobalFilterValue('');
		setFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const clearTextLabel = () => {
		setGlobalFilterValue('');
		setFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const sendInvitationsAction = async () => {
		const collaboratorsAlreadyAccepted = toSend.filter(
			(item) => item.invitationStatus === 2
		);
		collaboratorsAlreadyAccepted.forEach((collaborator) => {
			collaborator.error = 'Ya cuenta con una invitación aceptada';
		});
		const collaboratorsUnavailable = toSend.filter(
			(item) => item.email === null
		);
		collaboratorsUnavailable.forEach((collaborator) => {
			collaborator.error = 'El colaborador no cuenta con correo válido';
		});
		const listCollaborators = [
			...collaboratorsAlreadyAccepted,
			...collaboratorsUnavailable,
		];
		setCollaboratorsList([
			...collaboratorsAlreadyAccepted,
			...collaboratorsUnavailable,
		]);
		const filteredToSend = toSend.filter(
			(item) => item.invitationStatus !== 2 && item.email !== null
		);
		if (filteredToSend.length === 0 && isIndividual === false) {
			Toast(
				'warning',
				'Las invitaciones no pudieron ser enviadas',
				'Las invitaciones seleccionadas ya cuentan con una invitación activa o no cuentan con correo válido',
				'success'
			);
		} else {
			const dataToSend =
				filteredToSend.length > 0 && isIndividual === false
					? filteredToSend
					: [individualRow];
			const newDataToSend = dataToSend.map((item) => {
				return {
					id: item.id,
					email: item.email,
					name: item.name,
					firstLastName: item.firstLastName,
					secondLastName: item.secondLastName,
					invitationStatus: item.invitationStatus,
				};
			});
			let verifyAvailable = await verifyAvailableLicense(newDataToSend);

			if (
				(verifyAvailable !== undefined &&
					verifyAvailable !== null &&
					verifyAvailable?.data.data >= newDataToSend.length &&
					verifyAvailable?.data.data !== 0) ||
				verifyAvailable?.data.data === -1
			) {
				try {
					let response = null;
					response = isIndividual
						? await sendInvitation([individualRow])
						: await sendInvitation(newDataToSend);
					setToSend([]);
					const errorInvitations = response.data.data.errorInvitations;
					if (
						response.status === 200 &&
						errorInvitations.length === 0 &&
						listCollaborators.length === 0
					) {
						Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
					}
					if (errorInvitations.length > 0) {
						Toast('warning', AlertToastInfoNames.sendInvitationWarning, '', '');
						try {
							// Determina si es individual o múltiple y crea un array correspondiente
							const _dataMap = isIndividual ? [individualRow] : toSend;

							// Filtra los datos para incluir solo aquellos que tienen coincidencia en errorInvitations
							const mergeData = _dataMap.filter((item) => {
								return errorInvitations.some(
									(jsonItem) => jsonItem.email === item.email
								);
							});

							// Mapea los datos filtrados y añade la propiedad error desde errorInvitations
							const _mergeData = mergeData.map((item) => ({
								...item,
								error: errorInvitations.find(
									(jsonItem) => jsonItem.email === item.email
								)?.error,
							}));

							// Actualiza colaboradoresList con los nuevos datos
							setCollaboratorsList((prevList) => [...prevList, ..._mergeData]);
						} catch (error) {
							console.error('Error al analizar la cadena JSON:', error);
						}
					}
					if (listCollaborators.length > 0 || errorInvitations.length > 0) {
						setOpenModal(true);
					}

					return response;
				} catch (error) {}
			} else {
				Toast(
					'warning',
					'No hay licencias disponibles',
					AlertToastInfoNames.AlertLicenseAvailable,
					'success'
				);
			}
		}
	};
	const handleModalClose = () => {
		// Realizar acciones después de cerrar el modal
		setToSend([]);
		setTypeInvitation('');
		setCollaboratorsList([]);
	};
	const sendUnInviteAction = async () => {
		try {
			const dataToSend =
				toSend.length > 0 ? toSend.map((item) => item.id) : [individualRow.id];
			let response = await sendUnInvite(dataToSend);

			setToSend([]);
			// Realizar el merge
			const responseData = response.data.data;
			const mergedData = responseData
				.filter((id) => toSend.some((item) => item.id === id)) // Filtra los IDs que no están en toSend
				.map((id) => toSend.find((item) => item.id === id));
			if (mergedData.length > 0) {
				setOpenModal(true);
				setCollaboratorsList(mergedData);
			}
			return response;
		} catch (error) {
			console.log(error);
		}
	};

	const sendInvitationFunctionAction = (rowData) => {
		setOpenDialog(true);
		setIndividualRow(rowData);
		setTypeInvitation('invitation');
		setIsIndividual(true);
	};

	const sendUnInviteFunctionAction = (rowData) => {
		setOpenDialog(true);
		setIndividualRow(rowData);
		setTypeInvitation('un-invite');
		setIsIndividual(true);
	};

	const renderAlert = () => {
		return (
			<AlertComponent
				typeInvitation={typeInvitation}
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				sendInvitationsAction={sendInvitationsAction}
				sendUnInviteAction={sendUnInviteAction}
			/>
		);
	};
	const renderModal = () => {
		console.log('collaboratorsList in render modal', collaboratorsList);
		return (
			<ModalComponent
				typeInvitation={typeInvitation}
				openModal={openModal}
				setOpenModal={setOpenModal}
				sendInvitationsAction={sendInvitationsAction}
				sendUnInviteAction={sendUnInviteAction}
				collaboratorsList={collaboratorsList}
				handleModalClose={handleModalClose}
			/>
		);
	};
	const renderDialog = () => {
		return (
			<Dialog
				header='ALERTA'
				visible={showCloseDialog}
				style={{ width: '20vw' }}
				onHide={() => setShowCloseDialog(false)}>
				<p className='m-0'>{dialogContent}</p>
			</Dialog>
		);
	};

	const renderHeader = () => {
		return (
			<HeaderComponent
				size={size}
				globalFilterValue={globalFilterValue}
				setGlobalFilterValue={setGlobalFilterValue}
				setFilters={setFilters}
				onGlobalFilterChange={onGlobalFilterChange}
				filtersList={filtersList}
				getDataExport={getDataExport}
				acceptFunction={acceptFunction}
				dismissFunction={dismissFunction}
				filterOpc={filterOpc}
				filterOpcEmployee={filterOpcEmployee}
				selectFilter={selectFilter}
				selectFilterEmployee={selectFilterEmployee}
				handleFilterChange={handleFilterChange}
				handleFilterEmployee={handleFilterEmployee}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				clearFilters={clearFilters}
				clearTextLabel={clearTextLabel}
				companyInfo={companyState}
			/>
		);
	};

	const renderTable = () => {
		return (
			<TableComponent
				///loading={loadingState}
				filtersList={filtersList}
				renderHeader={renderHeader}
				filters={filters}
				toSend={toSend}
				setToSend={setToSend}
				setSort={setSort}
				sort={sort}
				setShowCloseDialog={setShowCloseDialog}
				setDialogContent={setDialogContent}
				sendInvitationFunctionAction={sendInvitationFunctionAction}
				sendUnInviteFunctionAction={sendUnInviteFunctionAction}
				loading={getInvitationsState.loading && !loadingState}
			/>
		);
	};

	return (
		<MainContainer
			nameScreen={InvitationsScreenNames.InvitationsScreenTitle}
			alertComponent={renderAlert()}>
			{renderDialog()}
			{renderTable()}
			{renderModal()}
		</MainContainer>
	);
};

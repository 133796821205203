import React, { useContext } from 'react';
import ToolBarThreeColumns from '../../../components/Table/ToolBarThreeColumns';
import {
	GeneralNames,
	InvitationsScreenNames,
} from '../../../namesConstants/names';
import { downloadFileBase64, getFileNameWithDateTime } from '../../../utils/utils';
import { headers, headersTranslations, wchCols } from '../const';
import { Toast } from '../../../components/toast';
import { StoreContext } from '../../../business/Provider';
import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
export const HeaderComponent = ({
	globalFilterValue,
	onGlobalFilterChange,
	filtersList,
	getDataExport,
	acceptFunction,
	dismissFunction,
	filterOpc,
	filterOpcEmployee,
	selectFilter,
	selectFilterEmployee,
	handleFilterChange,
	handleFilterEmployee,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	clearFilters,
	clearTextLabel,
	companyInfo,
}) => {
	const { dispatchLoading } = useContext(StoreContext);

	function formatDate(date) {
		console.log('date:  xxxxxxxxxxxxxxxxxxx', date);
		return date; //? new Intl.DateTimeFormat('es-ES').format(new Date(date)) : '';
	}

	function dataToExcel() {
		const data = getDataExport(filtersList);
		// modificar data en los campos requestDate y statusChangeDate para formato de fecha dd/mm/yyyy

		const dataExcel = data.map(
			({
				additionalInfo,
				email,
				formattedCode,
				fullName,
				invitationResponseDate,
				invitationSendingDate,
				invitationStatus,
				statusEmployee,
			}) => ({
				additionalInfo,
				email,
				formattedCode,
				fullName,
				invitationResponseDate:
					invitationResponseDate === 'No disponible'
						? ''
						: formatDate(invitationResponseDate),
				invitationSendingDate:
					invitationSendingDate === 'No disponible'
						? ''
						: formatDate(invitationSendingDate),
				invitationStatus,
				statusEmployee,
			})
		);
		const toExcel = {
			filename: 'Invitaciones',
			templateFileName: 'simple_template.xlsx',
			replace: [
				{
					type: 0,
					tag: '#simple.name',
					prop: 'name',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.company',
					prop: 'company',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#printDate',
					prop: 'printDate',
					default: new Date().toLocaleDateString(),
					format: ['numberFormat', 'dd mmmm yyyy'],
				},
				{
					type: 1,
					tag: '#simple.appName',
					prop: 'appName',
					default: 'Simple',
				},
				{
					type: 1,
					tag: '#simple.name',
					prop: 'name',
					default: 'Simple',
				},
				{
					type: 2,
					tag: '#simple.cols',
					prop: 'cols',
				},
				{
					type: 3,
					tag: '#simple.rows',
					prop: 'rows',
					colprop: 'cols',
				},
			],
			data: {
				company: companyInfo?.companies?.name ? companyInfo.companies.name : '',
				autoFilter: true,
				gridLinesVisible: true,
				printDate: new Date().toLocaleDateString(),
				appName: 'Colabora',
				name: 'Invitaciones',
				cols: [],
				rows: dataExcel,
			},
		};
		// cols = [{ title: "", prop:"", width: ""}]
		// title es el nombre de la columna y prop es el nombre de la propiedad del objeto
		// width es el ancho de la columna

		headers.forEach((header, index) => {
			const col = {
				title: headersTranslations[index],
				prop: header,
				width: wchCols[index].wch,
			};
			toExcel.data.cols.push(col);
		});
		return toExcel;
	}

	async function toExcelExport() {
		if (filtersList.length > 0) {
			dispatchLoading({ type: LOADING_ON });
			const toExcel = dataToExcel();
			const filName = getFileNameWithDateTime('Invitaciones');

			await downloadFileBase64(toExcel, 'xlsx', filName)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}

	const iconsData = [
		{ icon: 'send', title: 'Enviar Invitaciones', onClick: acceptFunction },
		{ icon: 'dismiss', title: 'Anular Invitaciones', onClick: dismissFunction },
		{ icon: 'clear', title: 'Limpiar Filtros', onClick: clearFilters },
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: toExcelExport,
		},
	];

	return (
		<ToolBarThreeColumns
			placeholder={InvitationsScreenNames.PlaceHolderDropdown}
			onChange={onGlobalFilterChange}
			value={globalFilterValue}
			filterOpc={filterOpc}
			filterOpcEmployee={filterOpcEmployee}
			handleFilterChange={handleFilterChange}
			handleFilterEmployee={handleFilterEmployee}
			selectFilter={selectFilter}
			selectFilterEmployee={selectFilterEmployee}
			iNeedStartDate
			iNeedEndDate
			startDate={startDate}
			endDate={endDate}
			setStartDate={setStartDate}
			setEndDate={setEndDate}
			placeholderNameAdditional={'Invitación'}
			clearTextLabel={clearTextLabel}
			iconsDataPanel={iconsData}
		/>
	);
};

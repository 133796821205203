import { getAccessToken, getLocalAccessToken } from '../Auth/auth/token';

import axios from 'axios';
import { setUserData } from '../Auth/auth/store/userSlice';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';

// exeption object
function ExceptionApi(message, status = 500, errorData = '') {
	this.status = status;
	this.message = message;
	this.errorData = errorData;
	this.name = 'ExceptionApi';
}
export default function useApi() {
	const { instance, accounts } = useMsal();

	const errorHandlers = (error) => {
		let statusCode = error?.response?.status;
		let errorDetail = error?.response?.data?.detail;
		let errorMessage =
			errorDetail === undefined || errorDetail === null
				? error.message
				: errorDetail;
		let statusCodeFinal =
			statusCode === undefined || statusCode === null ? 500 : statusCode;
		let exeptionApi = new ExceptionApi(errorMessage, statusCodeFinal);
		return exeptionApi;
	};

	let timeOffset = moment().utcOffset() / 60;

	// generic api call
	const genericApiCall = async (
		url,
		method,
		data = {},
		params = {},
		headers = {},
		attemp = 0
	) => {
		const accessToken = getLocalAccessToken();
		const ses = sessionStorage.getItem('sessionState');

		const combinedHeaders = {
			...headers,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			Authorization: `Bearer ${accessToken}`,
			sessionState: ses,
			timeOffset: timeOffset,
			origin: 'x-requested-with',
		};
		return axios({
			method,
			url,
			data,
			params,
			headers: combinedHeaders,
		})
			.then((res) => {
				return res;
			})
			.catch(async (error) => {
				if (attemp > 2) {
					//Magic number..3 intentos de obtener token correcto..
					throw new ExceptionApi(
						error?.response?.data?.error,
						error.response.status
					);
				}

				if (error.response.status === 401) {
					const token = await getAccessToken({ instance, accounts });
					//set token in local storage
					setUserData(token);
					//retry the request
					const count = attemp + 1;
					return genericApiCall(url, method, data, params, headers, count);
				} else if (
					error.response.status === 502 ||
					error.response.status === 504
				) {
					const count = attemp + 1;
					return genericApiCall(url, method, data, params, headers, count);
				} else if (error?.code === 'ERR_NETWORK') {
					let response = new ExceptionApi(error.message);
					throw response;
				} else if (
					error.response.status === 400 ||
					error.response.status === 404 ||
					error.response.status === 409 ||
					error.response.status === 410 ||
					error.response.status === 412 ||
					error.response.status === 401
				) {
					let response = new ExceptionApi(
						error.response.data.detail,
						error.response.status
					);

					// if (error?.response?.data?.error)
					// 	response = new ExceptionApi(
					// 		error.response.data.detail,
					// 		error.response.status
					// 	);

					throw response;
				}
				// else if (error.response.status === 410) {
				// 	let response = new ExceptionApi(error.response.error);
				// 	if (error?.response?.data?.error)
				// 		response = new ExceptionApi(
				// 			error.response.data.error,
				// 			error.response.status
				// 		);
				// 	throw response;
				// }
				else if (error.response.status === 500) {
					let response = new ExceptionApi(
						error.response.data.detail,
						500,
						error.response.data.errorData
					);
					throw response;
				} else {
					let response = new ExceptionApi(
						'Hubo un problema al obtener información del servidor. Intente más tarde'
					);

					if (error?.response?.data?.message)
						response = new ExceptionApi(
							error?.response?.data?.message,
							error?.response?.data?.status
						);
					if (error?.response?.data?.data?.message)
						response = new ExceptionApi(
							error?.response?.data?.data?.message,
							error.response.status
						);
					if (error?.response?.data?.error)
						response = new ExceptionApi(
							error?.response?.data?.error,
							error.status
						);
					throw response;
				}
			});
	};
	return {
		genericApiCall,
	};
}

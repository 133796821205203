import { Dropdown } from 'primereact/dropdown';

import React, { useContext, useState } from 'react';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import CustomDatePicker from '../../../../components/Calendar/CustomDatePicker';
import { Button } from 'primereact/button';
import { Toast } from '../../../../components/toast';
import moment from 'moment';
import { typeIncidences } from '../../../../utils/utils';
import { MultiSelectComponent } from '../../../../components/MultiSelect/MultiSelectComponent';
import { Calendar } from 'primereact/calendar';
import { FilterContext } from '../../../../context/queries/FilterContext';
export const FilterComponent = ({
	onSubmitFilter,
	periodType,
	collaborators,
	workCenters,
	positions,
	workShiftTypes,
	departments,
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const {
		typeQuery,
		typeQuerySelect,
		onChangeTypeQuery,
		typePeriodSelect,
		onChangeTypePeriodSelect,
		clearFilters,
		forceUpdateKey,
		forceUpdateKeyTwo,
		startDate,
		endDate,
		onChangeStartDate,
		onChangeEndDate,
		workCentersSelect,
		onChangeWorkCentersSelection,
		setWorkCentersSelect,
		shiftSelect,
		onChangeShiftsSelection,
		setShiftSelect,
		departmentSelect,
		setDepartmentSelect,
		onChangeDepartmentsSelection,
		positionSelect,
		setPositionSelect,
		onChangePositionsSelection,
		collaboratorsSelect,
		setCollaboratorsSelect,
		onChangeCollaboratorsSelection,
		incidentTypeSelect,
		setIncidentTypeSelect,
		onChangeIncidenTypes,
		setEndDate,
	} = useContext(FilterContext);

	function onSubmitButtonClick() {
		const newEndDate = moment(endDate);
		const newStartDate = moment(startDate);
		const daysDiff = newEndDate.diff(newStartDate, 'days');
		const newSetEndDate = newStartDate.clone().add(30, 'days'); // Aquí se añaden 30 días a la fecha de inicio
		// Verificar que la fecha de finalización no sea menor que la fecha de inicio
		if (newEndDate.isBefore(newStartDate)) {
			Toast(
				'warning',
				'La fecha de finalización no puede ser menor que la fecha de inicio.'
			);
			const date = new Date(newSetEndDate);
			setEndDate(date);
		} else if (daysDiff > 30) {
			// Verificar que la diferencia entre las fechas no sea mayor que 30 días
			Toast(
				'warning',
				'La diferencia entre las fechas no puede ser mayor de 30 días.'
			);
			const date = new Date(newSetEndDate);
			setEndDate(date);
		} else {
			const incidentData = {
				typeQuery: typeQuerySelect,
				startDate: startDate,
				endDate: endDate,
				TypePeriods: typePeriodSelect,
				workCenters: workCentersSelect,
				shifts: shiftSelect,
				departments: departmentSelect,
				positions: positionSelect,
				collaborators: collaboratorsSelect,
				typeIncidents: incidentTypeSelect,
				//totalizer: totalizersSelect,
			};

			const checksData = {
				typeQuery: typeQuerySelect,
				startDate: startDate,
				endDate: endDate,
				TypePeriods: typePeriodSelect,
				workCenters: workCentersSelect,
				shifts: shiftSelect,
				departments: departmentSelect,
				positions: positionSelect,
				collaborators: collaboratorsSelect,
			};
			return typeQuerySelect.code === 'I'
				? onSubmitFilter(incidentData)
				: onSubmitFilter(checksData);
		}
	}

	const renderMultiSelect = (
		id,
		name,
		label,
		placeholder,
		optionLabel,
		value,
		options,
		onChange,
		setState,
		filter
	) => {
		return (
			<MultiSelectComponent
				label={label}
				name={name}
				placeholder={placeholder}
				optionLabel={optionLabel}
				value={value}
				options={options}
				onChange={onChange}
				setState={setState}
				title={label}
				filter={filter}
			/>
		);
	};

	const renderDropComponent = (
		label,
		value,
		onChange,
		name,
		options,
		optionLabel,
		placeHolder
	) => {
		return (
			<div className='flex w-full h-18  flex-col pl-1 pr-1 lg:text-xs'>
				<div className='flex w-full h-full content-center items-center'>
					<label className=' font-bold '>{label}</label>
				</div>
				<div className='flex w-full h-full content-center items-center justify-start flex-col'>
					<Dropdown
						value={value}
						onChange={onChange}
						name={name}
						id={name}
						options={options}
						optionLabel={optionLabel}
						className={'!w-full !h-5 !flex  '}
						placeholder={placeHolder}
						disabled={options.length === 0}
					/>
				</div>
			</div>
		);
	};

	return (
		<div
			className={
				'flex   bg-white  border drop-shadow-2xl flex-col h-full' +
				(isOpen
					? ' sm:w-[20%]  md:w-[20%]  lg:w-[18%] xl:w-[16%] 2xl:w-[16%]'
					: ' w-16')
			}>
			<div className='flex content-end justify-end item-end'>
				{isOpen ? (
					<TiChevronLeft
						onClick={() => setIsOpen(false)}
						className={'text-2xl text-light-ShadowPCL004'}
					/>
				) : (
					<TiChevronRight
						onClick={() => setIsOpen(true)}
						className={'text-2xl text-light-ShadowPCL004'}
					/>
				)}
			</div>
			{isOpen === true ? (
				<div className='flex w-full h-full flex-col '>
					<div className='flex h-[90%] w-full  gap-y-[6px] lg:!gap-y-[8px] relative '>
						<div className='flex inset-0 flex-col overflow-auto absolute'>
							{renderDropComponent(
								'Tipo de consulta',
								typeQuerySelect,
								onChangeTypeQuery,
								'name',
								typeQuery,
								'name',
								'Seleccione un valor'
							)}
							<div className='flex w-full h-18  flex-col pl-1 pr-1 '>
								<div className='flex w-full h-full content-center items-center'>
									<label className=' font-bold text-sm lg:!text-xs'>
										{'Fecha Inicial'}
									</label>
								</div>
								<div className='flex w-full h-full content-center items-center justify-start flex-col'>
									<Calendar
										key={forceUpdateKey}
										showButtonBar
										id='icon'
										className={
											' !border-none !outline-none !p-0 !text-base !font-input-font-family !w-full lg:!text-xs'
										}
										value={startDate}
										onChange={onChangeStartDate}
										//minDate={minDate !== null ? minDate : null}
										todayButtonClassName='hidden'
										dateFormat='dd-MM-yy'
										showIcon
										placeholder={'Seleccione fecha inicial'}
										locale='es'
										name='searchEndDate'
									/>
								</div>
							</div>
							<div className='flex w-full h-18  flex-col p-1 '>
								<div className='flex w-full h-full content-center items-center'>
									<label className=' font-bold text-sm lg:!text-xs '>
										{'Fecha Final'}
									</label>
								</div>
								<div className='flex w-full h-full content-center items-center justify-start flex-col'>
									<Calendar
										key={forceUpdateKeyTwo}
										showButtonBar
										id='icon'
										className={
											' !border-none !outline-none !p-0 !text-base !font-input-font-family !w-full lg:!text-xs'
										}
										value={endDate}
										onChange={onChangeEndDate}
										//minDate={minDate !== null ? minDate : null}
										todayButtonClassName='hidden'
										dateFormat='dd-MM-yy'
										showIcon
										placeholder={'Seleccione fecha final'}
										locale='es'
										name='searchEndDate'
									/>
								</div>
							</div>
							{renderDropComponent(
								'Tipos de Periodos',
								typePeriodSelect,
								onChangeTypePeriodSelect,
								'name',
								periodType,
								'name',
								periodType.length > 0
									? 'Seleccione un tipo de periodo'
									: ' No se encontraron periodos'
							)}
							<div className='flex w-full h-18'>
								{renderMultiSelect(
									'name',
									'name',
									'Centros de trabajo',
									workCenters.length > 0
										? 'Seleccione un centro de trabajo'
										: ' No se encontraron Centros de trabajo',
									'name',
									workCentersSelect,
									workCenters,
									onChangeWorkCentersSelection,
									setWorkCentersSelect,
									true
								)}
							</div>
							<div className='flex w-full h-18'>
								{renderMultiSelect(
									'name',
									'name',
									'Turnos',
									workShiftTypes.length > 0
										? 'Seleccione turnos'
										: ' No se encontraron turnos',
									'name',
									shiftSelect,
									workShiftTypes,
									onChangeShiftsSelection,
									setShiftSelect
								)}
							</div>
							<div className='flex w-full h-18'>
								{renderMultiSelect(
									'name',
									'name',
									'Departamentos',
									departments.length > 0
										? 'Seleccione departamentos'
										: ' No se encontraron departamentos',
									'name',
									departmentSelect,
									departments,
									onChangeDepartmentsSelection,
									setDepartmentSelect
								)}
							</div>
							<div className='flex w-full h-18'>
								{renderMultiSelect(
									'name',
									'name',
									'Puestos',
									positions.length > 0
										? 'Seleccione un puestos'
										: 'No se encontraron puestos',
									'name',
									positionSelect,
									positions,
									onChangePositionsSelection,
									setPositionSelect
								)}
							</div>
							<div className='flex w-full h-18'>
								{renderMultiSelect(
									'collaborators',
									'collaborators',
									'Colaboradores',
									collaborators.length > 0
										? 'Seleccione colaboradores'
										: 'No se encontraron colaboradores',
									'name',
									collaboratorsSelect,
									collaborators,
									onChangeCollaboratorsSelection,
									setCollaboratorsSelect,
									true
								)}
							</div>
							{typeQuerySelect?.code === 'I' ? (
								<div className='flex w-full h-18'>
									{renderMultiSelect(
										'name',
										'name',

										'Tipos de incidencias',
										typeIncidences.length > 0
											? 'Seleccione una incidencia'
											: 'No se encontraron incidencias',
										'name',
										incidentTypeSelect,
										typeIncidences,
										onChangeIncidenTypes,
										setIncidentTypeSelect
									)}
								</div>
							) : null}
						</div>
					</div>
					<div className='flex w-full h-12 justify-end content-end items-end  '>
						<div className='flex w-full h-full  flex-row py-1 pl-[4px] pr-[4px] content-center items-center justify-between'>
							<Button
								label='Limpiar'
								className='!flex !w-28 !h-full !bg-white !text-black !text-xs lg:!w-[68px]'
								aria-label='cancel'
								onClick={() => clearFilters()}
							/>
							<Button
								label='Consultar'
								className='!flex !w-28 !h-full !text-xs lg:!w-[68px]'
								aria-label='Submit'
								type='submit'
								onClick={() => onSubmitButtonClick()}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

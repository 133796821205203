import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { GeneralNames } from './../../namesConstants/names';

const CustomDropdown = ({
	value,
	name,
	label,
	handler,
	optionValue,
	optionLabel,
	className,
	classNameCustom,
	options,
}) => {
	return (
		<>
			<div className={'flex flex-col w-full h-full' + className}>
			<span className="p-float-label w-full md:w-14rem">
				<Dropdown
					className={
						className || classNameCustom
							? ` !font-input-font-family w-full h-full !text-xs 2xl:!text-base ${classNameCustom ? classNameCustom : ''}`
							: ''
					}
					name={name}
					value={value}
					onChange={(e) => handler(e)}
					options={options}
					optionLabel={optionLabel}
					optionValue={optionValue}
					label={label}
					emptyMessage={<>{GeneralNames.GeneralEmptyOptions}</>}
				/>
				<label htmlFor="dd-city"></label>
			</span>
				
			</div>
		</>
	);
};

export default CustomDropdown;

import { Column } from 'jspdf-autotable';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { GeneralNames } from '../../../../namesConstants/names';
import { FilterContext } from '../../../../context/queries/FilterContext';
import { useContext } from 'react';
export const TableQueriesComponent = ({
	tableDataQuery,
	dynamicColumns,
	filters,
	sort,
	setSort,
	keyTable,
}) => {
	const { showColumnsDate } = useContext(FilterContext);
	const headerClassNameColumn =
		'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !font-bold !text-[14px] !font-karla ';
	const bodyClassName =
		'!border-b-0 !border-secondary-PCS004 !text-black !justify-start ';

	const dynamicColumnsTableOpen = dynamicColumns.open.map((col, i) => {
		return (
			<Column
				columnKey={i}
				style={{ width: '150px' }}
				key={col.field}
				field={col.field}
				header={col.header}
				frozen={col.frozen}
				sortable={col.sortable}
				headerClassName={headerClassNameColumn}
				bodyClassName={bodyClassName}
				alignFrozen={col.alignFrozen}
				body={(rowData) => {
					//console.log(rowData[col.field]);
					return col?.body !== undefined
						? col?.body(rowData[col.field], col.field)
						: rowData[col.field];
				}}
			/>
		);
	});
	const dynamicColumnsTableClose = dynamicColumns.close.map((col, i) => {
		return (
			<Column
				columnKey={i}
				style={{ width: '150px' }}
				key={col.field}
				field={col.field}
				header={col.header}
				frozen={col.frozen}
				sortable={col.sortable}
				headerClassName={headerClassNameColumn}
				bodyClassName={bodyClassName}
				alignFrozen={col.alignFrozen}
				body={(rowData) => {
					//console.log(rowData[col.field]);
					return col?.body !== undefined
						? col?.body(rowData[col.field], col.field)
						: rowData[col.field];
				}}
			/>
		);
	});
	return (
		<div className='flex w-full h-full relative'>
			{tableDataQuery !== null ? (
				<DataTable
					key={keyTable}
					value={tableDataQuery}
					paginator
					rows={25}
					first={0}
					sortField={sort.field}
					sortOrder={sort.order}
					scrollable
					size='small'
					scrollHeight='flex'
					//columnResizeMode='fit'
					filters={filters}
					emptyMessage={GeneralNames.GeneralEmptyMessage}
					//showGridlines
					globalFilterFields={[
						'Code',
						'CollaboratorName',
						'CollaboratorLead',
						'TotalHome',
						'TotalWork',
						'TotalUnknown',
						'timeAttendance',
						'quantityAttendance',
						'timeAbscense',
						'quantityAbscense',
						'timeDelay',
						'quantityDelay',
					]}
					rowsPerPageOptions={[25, 50, 75, 100]}
					className='!flex !flex-col !w-full !h-full !relative !bg-transparent '
					tableStyle={{
						height: '100% !important',
						minHeight: '100% !important',
						background: 'none',
						backgroundColor: 'none',
					}}
					onSort={(e) => setSort(e.multiSortMeta)}
					multiSortMeta={sort}
					sortMode='multiple'
					tableClassName='!overflow-scroll !flex-col !absolute  inset-0 '
					rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004  '
					paginatorClassName='!border-t !border-border !justify-start !text-xs !top-0 flex bg-withe'
					paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown '
					currentPageReportTemplate='Total de registros {totalRecords}'
					responsiveLayout='scroll'>
					{showColumnsDate ? dynamicColumnsTableOpen : dynamicColumnsTableClose}
				</DataTable>
			) : null}
		</div>
	);
};

import React, { useContext } from 'react';
import useApi from '../api';
import { LOADING_OFF, LOADING_ON } from '../../business/constants';

import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';

import useCompanySetting from '../company/useCompanySetting';

export const useIntegrations = () => {
	const { genericApiCall } = useApi();
	const context = useContext(StoreContext);
	const { dispatchLoading, companyState } = context;
	const { companies } = companyState;
	const { getCompanySettings } = useCompanySetting();

	const postIntegrations = async () => {
		const baseUrl =
			window.REACT_INTEGRATIONS_API_URL +
			'/v1/integration/process-direct-message';
		dispatchLoading({ type: LOADING_ON });
		//validacion origen
		const isPayrollCall = JSON.parse(sessionStorage.getItem('isPayrollCall'));
		const data = {
			contentType: '',
			messageConfig: {
				sincronizationId: 'd336e881-78d8-477a-b12a-cda03d8b6587',
				type: 50,
				instanceId: companies?.instanceId,
				companyId: companies?.companyId,
			},
			body: { origin: isPayrollCall ? 30 : 10 },
		};

		try {
			const result = await genericApiCall(baseUrl, 'POST', data, {}, {});

			//const payload = result.data;
			if (result?.status === 200) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se ha importado con éxito ');
				return await getCompanySettings();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};
	return {
		postIntegrations,
	};
};

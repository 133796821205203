import { MultiSelect } from 'primereact/multiselect';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const MultiSelectComponent = ({
	id,
	value,
	optionLabel,
	options,
	onChange,
	placeholder,
	title,
}) => {
	const _placeholder = placeholder;

	const [placeHolderMessage, setPlaceHolderMessage] = useState(placeholder);

	useEffect(() => {
		let message = '';
		if (value.length === options.length) {
			message = 'Todos';
		} else if (value.length > 0) {
			message = `${value.length} elementos seleccionados`;
		} else {
			console.log('placeholder', _placeholder);
			message = _placeholder;
		}
		setPlaceHolderMessage(message);
	}, [value, _placeholder, options]);

	const panelFooterTemplate = () => {
		const length = value ? value.length : 0;
		return length === options.length ? (
			<div className='py-2 px-3'>
				<b>Todos</b>
			</div>
		) : (
			<div className='py-2 px-3'>
				<b>{length}</b> elemento{length !== 1 ? 's' : ''} seleccionados.
			</div>
		);
	};

	return (
		<div className='flex w-full h-full flex-col pl-1 pr-1 lg:text-xs'>
			<div className='flex w-full h-full content-center items-center'>
				<label className='font-bold  '>{title}</label>
			</div>
			<div className='flex w-full h-full content-center items-center flex-col'>
				<MultiSelect
					key={id}
					value={value}
					options={options}
					onChange={onChange}
					optionLabel={optionLabel}
					className='!w-full !h-5 '
					placeholder={placeHolderMessage}
					filter
					fixedPlaceholder
					selectAll
					disabled={options.length === 0}
					panelFooterTemplate={panelFooterTemplate}
				/>
			</div>
		</div>
	);
};

MultiSelectComponent.propTypes = {
	id: PropTypes.string,
	value: PropTypes.array.isRequired,
	optionLabel: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	title: PropTypes.string.isRequired,
};

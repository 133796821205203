import { React, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
//components
import { CustomTable } from '../../../components/Table/CustomTable';

//utils and constants
import { EmployeeStatusEnum } from '../const';
import {
	StatusArrayValuesTable,
	formatDateInvitations,
} from '../../../utils/utils';
import {
	GeneralNames,
	InvitationsScreenNames,
	TableNames,
} from '../../../namesConstants/names';
import { headerClassName } from '../../../assets/customStyles';
import { RiMailSendLine } from 'react-icons/ri';
import { TbMailOff } from 'react-icons/tb';
import moment from 'moment';

export const TableComponent = ({
	filtersList,
	renderHeader,
	//loading,
	filters,
	toSend,
	setToSend,
	setSort,
	sort,
	setShowCloseDialog,
	setDialogContent,
	sendInvitationFunctionAction,
	sendUnInviteFunctionAction,
	loading,
}) => {
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';

	const renderValidationButton = (rowData) => {
		let validation = true;
		if (rowData?.email === null) {
			validation = false;
		} else if (
			rowData?.invitationStatus === 4 ||
			rowData?.invitationStatus === 2 ||
			rowData?.invitationStatus === 1
		) {
			validation = rowData.resend ? true : false;
		} else if (rowData?.additionalInfo !== null && rowData?.resend === false) {
			validation = false;
		} else if (rowData?.additionalInfo !== null) {
			validation = true;
		} else if (rowData?.resend === true && rowData?.additionalInfo !== null) {
			validation = true;
		} else if (rowData?.statusEmployee === 1) {
			validation = false;
		}
		return validation;
	};
	const informationTemplate = (rowData) => {
		return rowData.additionalInfo !== null ? (
			<div
				onClick={() => {
					setShowCloseDialog(true);
					setDialogContent(rowData.additionalInfo);
				}}
				title={rowData.additionalInfo}
				className='flex w-3 h-3   rounded-full justify-center content-center items-center '>
				<FontAwesomeIcon
					size='sm'
					className='pi text-secondary-PCS002 text-center  '
					icon={faWarning}
				/>
			</div>
		) : null;
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center content-center items-center'>
				{renderValidationButton(rowData) === true ? (
					<div
						onClick={() => sendInvitationFunctionAction(rowData)}
						title={InvitationsScreenNames.TextTooltip}
						className='flex w-4 h-4   justify-center content-center items-center cursor-pointer'>
						<RiMailSendLine className='text-black text-2xl' />
					</div>
				) : null}
				{rowData.invitationStatus === 2 || rowData.invitationStatus === 1 ? (
					<div
						onClick={() => sendUnInviteFunctionAction(rowData)}
						title={InvitationsScreenNames.TextTooltipUnInvite}
						className='flex w-4 h-4   justify-center content-center items-center cursor-pointer'>
						<TbMailOff className='text-black text-2xl' />
					</div>
				) : null}
			</div>
		);
	};
	return (
		<CustomTable
			value={filtersList}
			loading={loading}
			header={renderHeader()}
			//loading={loading}
			sortField='statusEmployee'
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={[
				'name',
				'statusEmployee',
				'invitationSendingDate',
				'code',
				'email',
				'invitationStatus',
				'invitationResponseDate',
				'additionalInfo',
			]}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={
				InvitationsScreenNames.CurrentPageReportTemplate
			}
			resizableColumns
			columnResizeMode='fit'
			//	style={{ height: '100%', width: '100%' }}
			selection={toSend}
			selectionMode='checkbox'
			onSelectionChange={(e) => setToSend(e.value)}
			setSort={setSort}
			sort={sort}>
			<Column
				headerClassName={headerClassName}
				bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
				selectionMode='multiple'
				style={{ minWidth: '10px' }}></Column>

			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '50px' }}
				field='code'
				header={TableNames.TableCode}
				sortable
				bodyClassName={bodyClassName}
				body={(rowData) => {
					return rowData.code;
				}}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='fullName'
				header={TableNames.TableFirstName}
				sortable
				bodyClassName={bodyClassName}
				body={(rowData) => {
					return rowData.fullName;
				}}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='email'
				header={TableNames.TableEmail}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='statusEmployee'
				header={TableNames.TableStatus}
				sortable
				bodyClassName={bodyClassName}
				body={(rowData) => {
					return EmployeeStatusEnum[rowData.statusEmployee];
				}}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 3, flexBasis: '100px' }}
				field='invitationSendingDate'
				header={TableNames.TableDateToInvite}
				sortable
				dataType='date'
				bodyClassName={bodyClassName}
				body={(rowData) => {
					// Check if invitationSendingDate is not null and is a valid date string
					if (rowData.invitationSendingDate !== null) {
						const formattedDate = moment(rowData.invitationSendingDate).format(
							'DD/MM/YYYY'
						);
						return formattedDate !== 'Invalid date'
							? formattedDate
							: 'Fecha no válida';
					}
					return 'No disponible';
				}}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 3, flexBasis: '100px' }}
				field='invitationResponseDate'
				header={TableNames.TableDateToResponse}
				sortable
				dataType='date'
				bodyClassName={bodyClassName}
				body={(rowData) => {
					// Check if invitationSendingDate is not null and is a valid date string
					if (rowData.invitationResponseDate !== null) {
						const formattedDate = moment(rowData.invitationResponseDate).format(
							'DD/MM/YYYY'
						);
						return formattedDate !== 'Invalid date'
							? formattedDate
							: 'Fecha no válida';
					}
					return 'No disponible';
				}}
			/>

			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 3, flexBasis: '100px' }}
				field='invitationStatus'
				header={TableNames.TableStatusInvitation}
				sortable
				bodyClassName={bodyClassName}
				body={(rowData) => {
					const filter = StatusArrayValuesTable.filter(
						(item) => item.value === rowData.invitationStatus
					)[0].label;
					return rowData.email === null || rowData.email === ''
						? 'No disponible'
						: filter;
				}}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='additionalInfo'
				header={'Información'}
				sortable
				bodyClassName={bodyClassName}
				body={informationTemplate}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				header={TableNames.TableActions} //Para eliminar encuesta
				body={actionBodyTemplate}
				bodyClassName={bodyClassName}
			/>
		</CustomTable>
	);
};

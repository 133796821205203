import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { ImFileExcel, ImFilePdf, ImCross, ImCheckmark } from 'react-icons/im';
import { IoAddCircleOutline } from 'react-icons/io5';
import { TbFilterOff, TbMailOff } from 'react-icons/tb';
import { PiClockCountdownThin } from 'react-icons/pi';
import { RiMailSendLine } from 'react-icons/ri';
import { MdScheduleSend } from 'react-icons/md';


export const IconsPanelControl = ({ iconsData = [] }) => {
	const renderIcon = (dataIcon) => {
		const classNameIcon =
			dataIcon?.small === true ? 'text-xl text-white' : 'text-2xl text-white';
		switch (dataIcon.icon) {
			case 'excel':
				return <ImFileExcel className={classNameIcon} />;
			case 'add':
				return <IoAddCircleOutline className={classNameIcon} />;
			case 'search':
				return <AiOutlineSearch className={classNameIcon} />;
			case 'clear':
				return <TbFilterOff className={classNameIcon} />;
			case 'accept':
				return <ImCheckmark className={classNameIcon} />;
			case 'reject':
				return <ImCross className={classNameIcon} />;
			case 'send':
				return <RiMailSendLine className={classNameIcon} />;
			case 'dismiss':
				return <TbMailOff className={classNameIcon} />;
			case 'turn':
				return <PiClockCountdownThin className={classNameIcon} />;
			case 'sendEvidences':
				return <MdScheduleSend className={classNameIcon} />;
			default:
				return <ImFilePdf className={classNameIcon} />;
		}
	};

	return (
		<div className='flex w-full h-full bg-primary-ShadowPCP004 rounded-md justify-end items-center content-center'>
			{iconsData.map((icon, i) => {
				const key = i.toString();
				return (
					<div
						key={key}
						onClick={() => icon.onClick()}
						title={icon.title}
						className='flex w-4 h-4  justify-center content-center items-center text-white cursor-pointer  '>
						{renderIcon(icon)}
					</div>
				);
			})}
		</div>
	);
};

import { Route } from 'react-router-dom';
import { AssistScreen } from '../screens/Assist';
import { CollaboratorScreen } from '../screens/Collaborators';
import { HomeScreen } from '../screens/Home';
import { VacationScreen } from '../screens/Vacations';
import { InvitationsScreen } from './../screens/Invitations/index';
import { Logout } from './../screens/Logout/index';
import { NotFound } from './../components/NotFound/index';
import { ConfigurationScreen } from '../screens/Configuration';
import { Catalogs } from '../screens/Catalogs';
import { Operations } from '../screens/Operation/Index';
import { IncidencesScreen } from '../screens/Incidenses';
import { QueriesComponent } from '../screens/Queries';
import ErrorBoundary from '../components/ErrorComponent/ErrorBoundary';
import { SendIncidencesServiceManagerProvider } from '../Services/Incidences/SendIncidencesServices';

const routerBrowser = [
	{
		path: '/',
		name: 'Home',
		element: <HomeScreen />,
	},
	{
		path: '/applications',
		name: 'Applications',
		element: (
			<ErrorBoundary>
				<CollaboratorScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/vacations',
		name: 'Vacations',
		element: (
			<ErrorBoundary>
				<VacationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/assist',
		name: 'Assist',
		element: (
			<ErrorBoundary>
				<AssistScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/invitations',
		name: 'Invitations',
		element: (
			<ErrorBoundary>
				<InvitationsScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/centralModuleLogout',
		name: 'logout',
		element: <Logout />,
	},
	// {
	// 	path: '/catalogs/workAreas',
	// 	name: 'workAreas',
	// 	element: <WorkArea />,
	// },
	// {
	// 	path: '/catalogs/schedules',
	// 	name: 'schedules',
	// 	element: <Schedules />,
	// },
	// {
	// 	path: '/catalogs/events',
	// 	name: 'events',
	// 	element: <Events />,
	// },

	// {
	// 	path: '/catalogs/prenominalPeriods',
	// 	name: 'prenominalPeriods',
	// 	element: <PrenominalPeriods />,
	// },
	// {
	// 	path: '/catalogs/holidays',
	// 	name: 'holidays',
	// 	element: <Holidays />,
	// },
	{
		path: '/catalogs',
		name: 'catalogs',
		element: (
			<ErrorBoundary>
				<Catalogs />
			</ErrorBoundary>
		),
	},
	{
		path: '/configuration',
		name: 'configuration',
		element: (
			<ErrorBoundary>
				<ConfigurationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/operation',
		name: 'operation',
		element: (
			<ErrorBoundary>
				<Operations />
			</ErrorBoundary>
		),
	},
	{
		path: '/incidences',
		name: 'incidences',
		element: (
			<ErrorBoundary>
				<SendIncidencesServiceManagerProvider>
					<IncidencesScreen />
				</SendIncidencesServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/queries',
		name: 'queries',
		element: (
			<ErrorBoundary>
				<QueriesComponent />
			</ErrorBoundary>
		),
	},
	{
		path: '*',
		name: 'NotFound',
		element: <NotFound />,
	},
];

export default routerBrowser.map((route) => {
	return <Route key={route.name} path={route.path} element={route.element} />;
});

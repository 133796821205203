/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import {
	LOADING_ON,
	LOADING_OFF,
	GET_SCHEDULES_SUCCESS,
	POST_SCHEDULES_SUCCESS,
	PUT_SCHEDULES_SUCCESS,
	GET_SCHEDULES_WORKSHIFT_SUCCESS,
} from '../../../business/constants';
import { generateUrlBase } from '../../../utils/utils';
import useApi from '../../api';
import { Toast } from '../../../components/toast';

const useSchedules = () => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();

	const {
		dispatchLoading,
		dispatchGetSchedule,
		dispatchPostSchedule,
		dispatchPutSchedule,
		dispatchGetScheduleWorkShift,
	} = context;
	const [schedules, setSchedules] = useState(undefined);
	const [workShifts, setWorkShifts] = useState(undefined);

	const getWorkShift = async () => {
		const baseUrl = generateUrlBase(`work-shift`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetScheduleWorkShift({
					type: GET_SCHEDULES_WORKSHIFT_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setWorkShifts(payload);
			}

			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getSchedules = async () => {
		const baseUrl = generateUrlBase('work-shift/detail');
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetSchedule({
					type: GET_SCHEDULES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setSchedules(payload);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postSchedules = async (data) => {
		const baseUrl = generateUrlBase('work-shift/detail');
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', data, {}, {});
			const payload = result.data;
			if (result.status === 200 || result.status === 201) {
				Toast('success', 'Se ha creado el horario de forma correcta');
				dispatchPostSchedule({
					type: POST_SCHEDULES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				return getSchedules();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const putSchedules = async (data, id) => {
		const baseUrl = generateUrlBase(`work-shift/detail/${id}`);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			const payload = result.data;
			if (result.status === 200 || result.status === 201) {
				Toast('success', 'El horario se ha actualizado de forma satisfactoria');
				dispatchPutSchedule({
					type: PUT_SCHEDULES_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setSchedules(payload);
				return getSchedules();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (schedules === undefined) {
			getSchedules();
		}
	}, [schedules]);

	useEffect(() => {
		if (workShifts === undefined) {
			getWorkShift();
		}
	}, [workShifts]);

	return {
		getSchedules,
		postSchedules,
		putSchedules,
		getWorkShift,
	};
};

export default useSchedules;

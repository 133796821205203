import { useState, useContext, useEffect } from 'react';
import {
	GET_INVITATIONS_ERROR,
	GET_INVITATIONS,
	GET_INVITATIONS_SUCCESS,
	SEND_INVITATIONS,
	SEND_INVITATIONS_SUCCESS,
	SEND_INVITATIONS_ERROR,
	GET_STATUS_INVITATIONS,
	GET_STATUS_INVITATIONS_ERROR,
	GET_STATUS_INVITATIONS_SUCCESS,
	RE_IMPORT_DATA,
	RE_IMPORT_DATA_SUCCESS,
	RE_IMPORT_DATA_ERROR,
	VERIFY_AVAILABLE_LICENSE,
	VERIFY_AVAILABLE_LICENSE_ERROR,
	VERIFY_AVAILABLE_LICENSE_SUCCESS,
	SEND_UN_INVITE_ERROR,
	SEND_INVITATIONS_ERROR_SHOW,
	INVITATION_FORWARD_ERROR_SHOW,
	INVITATION_FORWARD_ERROR,
	INVITATION_FORWARD,
	INVITATION_FORWARD_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import { StoreContext } from './../../business/Provider';
import { Toast } from './../../components/toast/index';
import useApi from '../api';
import {
	SEND_UN_INVITE,
	SEND_UN_INVITE_SUCCESS,
} from './../../business/constants';
import { generateUrlBase } from '../../utils/utils';
import { AlertToastInfoNames } from '../../namesConstants/names';

const useInvitations = () => {
	const [invitations, setInvitations] = useState(undefined);
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const {
		getInvitationsState,
		dispatchGetInvitations,
		dispatchPostInvitations,
		dispatchSendUnInvite,
		dispatchGetStatusInvitation,
		dispatchReImportData,
		dispatchVerifyAvailableLicense,
		invitationForward,
		dispatchInvitationForward,
		dispatchLoading,
	} = context;

	const getInvitations = async () => {
		dispatchGetInvitations({ type: GET_INVITATIONS });
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('collaborators');
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data.results;
			const newPayload = payload.map((item) => {
				return {
					...item,
					invitationResponseDate:
						item.invitationResponseDate !== null
							? new Date(item.invitationResponseDate)
							: item.invitationResponseDate,
					invitationSendingDate:
						item.invitationSendingDate !== null
							? new Date(item.invitationSendingDate)
							: item.invitationSendingDate,
				};
			});

			if (result.status === 200) {
				dispatchGetInvitations({
					type: GET_INVITATIONS_SUCCESS,
					payload: newPayload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetInvitations({
					type: GET_INVITATIONS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchGetInvitations({ type: GET_INVITATIONS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const sendInvitation = async (receiveData) => {
		dispatchPostInvitations({ type: SEND_INVITATIONS });
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('invitations/invite');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const collaborators = result.data.results;
			if (result.status === 200) {
				dispatchPostInvitations({
					type: SEND_INVITATIONS_SUCCESS,
					payload: collaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
				const resultAwait = await getInvitations();
				return result;
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPostInvitations({
					type: SEND_INVITATIONS_ERROR_SHOW,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchPostInvitations({ type: SEND_INVITATIONS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			return error;
		}
	};

	const sendInvitationForward = async (receiveData) => {
		// invitationForward,
		// 	dispatchInvitationForward,
		dispatchInvitationForward({ type: INVITATION_FORWARD });
		dispatchLoading({ type: LOADING_ON });
		try {
			const baseUrl = generateUrlBase('invitations/forward');
			const result = await genericApiCall(baseUrl, 'POST', receiveData, {}, {});
			const collaborators = result.data.results;
			if (result.status === 200) {
				dispatchInvitationForward({
					type: INVITATION_FORWARD_SUCCESS,
					payload: collaborators,
				});

				dispatchLoading({ type: LOADING_OFF });
				const resultAwait = await getInvitations();
				Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
				return resultAwait;
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchInvitationForward({
					type: INVITATION_FORWARD_ERROR_SHOW,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			Toast('success', AlertToastInfoNames.SendInvitationSuccess, '', '');
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchInvitationForward({ type: INVITATION_FORWARD_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	// const getInvitationStatus = async (type) => {
	// 	if (type === 'reload') {
	// 		dispatchGetStatusInvitation({ type: GET_STATUS_INVITATIONS });
	// 	}
	// 	dispatchLoading({ type: LOADING_ON });
	// 	const baseUrl = generateUrlBase('import-data');
	// 	try {
	// 		const result = await genericApiCall(baseUrl, 'POST', {}, {}, {});
	// 		if (result.status === 200) {
	// 			if (type === 'reload') {
	// 				//console.log('result.data', result.data);
	// 				dispatchGetStatusInvitation({
	// 					type: GET_STATUS_INVITATIONS_SUCCESS,
	// 					payload: result.data,
	// 				});
	// 				dispatchLoading({ type: LOADING_OFF });
	// 				Toast('success', 'Estatus actualizados con éxito', '', '');
	// 				const resultAwait = await getInvitations();
	// 				return resultAwait;
	// 			} else {
	// 				dispatchGetStatusInvitation({
	// 					type: GET_STATUS_INVITATIONS_SUCCESS,
	// 					payload: result.data,
	// 				});
	// 				dispatchLoading({ type: LOADING_OFF });
	// 				return result.data;
	// 			}
	// 		}
	// 	} catch (error) {
	// 		Toast('warning', error.message, '', error);
	// 		dispatchGetStatusInvitation({ type: GET_STATUS_INVITATIONS_ERROR });
	// 		dispatchLoading({ type: LOADING_OFF });
	// 	}
	// };

	const sendUnInvite = async (receiveData) => {
		const baseUrl = generateUrlBase('invitations/invite');
		dispatchSendUnInvite({ type: SEND_UN_INVITE });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(
				baseUrl,
				'DELETE',
				receiveData,
				{},
				{}
			);
			const collaborators = result.data;
			if (result.status === 200) {
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_SUCCESS,
					payload: collaborators,
				});
				dispatchLoading({ type: LOADING_OFF });
				const resultAwait = await getInvitations();
				if (result.data.data.length > 0) {
					Toast('warning', AlertToastInfoNames.sendUnInvitationWarning, '', '');
				} else {
					Toast('success', AlertToastInfoNames.sendUnInvitationSuccess, '', '');
				}
				return result;
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
		} catch (error) {
			dispatchSendUnInvite({ type: SEND_UN_INVITE_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const reImportDataUser = async (arrayInfoData) => {
		dispatchReImportData({ type: RE_IMPORT_DATA });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(
				generateUrlBase('import-data'),
				'POST',
				arrayInfoData,
				{},
				{}
			);
			if (result.status === 200) {
				dispatchReImportData({
					type: RE_IMPORT_DATA_SUCCESS,
					payload: result.data,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', AlertToastInfoNames.ReImportDataSuccess, '', '');
				return result;
			}
		} catch (error) {
			dispatchReImportData({ type: RE_IMPORT_DATA_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const verifyAvailableLicense = async (dataSend) => {
		dispatchVerifyAvailableLicense({ type: VERIFY_AVAILABLE_LICENSE });
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(
				generateUrlBase('invitations/verify-available'),
				'POST',
				{},
				{},
				{}
			);
			if (result.status === 200) {
				if (result.data !== -1 && result.data < dataSend.length) {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_ERROR,
					});
					dispatchLoading({ type: LOADING_OFF });
					Toast(
						'warning',
						AlertToastInfoNames.MaxLicenseAvailable,
						AlertToastInfoNames.AlertLicenseAvailable,
						''
					);
				} else {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_SUCCESS,
						payload: result.data,
					});
					dispatchLoading({ type: LOADING_OFF });
					return result;
				}
			}
		} catch (error) {
			dispatchVerifyAvailableLicense({ type: VERIFY_AVAILABLE_LICENSE_ERROR });
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	useEffect(() => {
		getInvitations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		invitations,
		setInvitations,
		getInvitations,
		sendInvitation,
		sendUnInvite,

		reImportDataUser,
		verifyAvailableLicense,
		sendInvitationForward,
	};
};

export default useInvitations;
